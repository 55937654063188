/*
  Sets a grid structure in child elements that are inside a parent with .grid classname.
  Matches child's column structure with parent's column structure without breaking the container.
*/
/*
  Sets a image variation.
*/
:root {
  --color-blue: #1a73e8;
  --color-dark-blue: #185abc;
  --color-link-blue: #1967d2;
  --color-dark-green: #153522;
  --color-dark-grey: #423f3f;
  --color-dark-grey-second: #1b211d;
  --color-dark-sand: #f1efe6;
  --color-dark-sand-second: #faf8f0;
  --color-dark-ray: #d3f104;
  --color-drew: #8BF1CE;
  --color-footer-grey-second: #f8f9fa;
  --color-footer-grey-third: #dadce0;
  --color-footer-grey: #80868b;
  --color-forest-shade: #1b2f27;
  --color-forest: #305e4b;
  --color-form-placeholder: #cccccc;
  --color-google-800: #3c4043;
  --color-green-300: #E0EBD9;
  --color-green-400: #f8f9fb;
  --color-green-600: #1e8e3e;
  --color-green-700: #188038;
  --color-green-800: #137333;
  --color-green-900: #0d652d;
  --color-grey: #9aa0a6;
  --color-grey-100: #F2F3F5;
  --color-grey-700: #5f6267;
  --color-grey-900: #202124;
  --color-handraiser-dynamic: #305e4b;
  --color-light-ray: #e0ff0e;
  --color-light-blue: #8ab4f8;
  --color-light-pink: #FFBCB7;
  --color-ray: #e9fd60;
  --color-renew-tonal: rgba(0, 216, 99, 0.25);
  --color-renew: #00d863;
  --color-sand: #faf8ef;
  --color-shade: #121b17;
  --color-white: #fff;
  --color-black: #000000;
  --color-shadow-1: rgba(60, 64, 67, 0.15);
  --color-shadow-2: rgba(60, 64, 67, 0.3);
  --color-earth: #C0B9AD;
  --color-earth-light: rgba(192, 185, 173, 0.16);
  --color-purple: #7D92E5;
}

/* http://meyerweb.com/eric/tools/css/reset/
   v2.0 | 20110126
   License: none (public domain)
*/
a,
abbr,
acronym,
address,
applet,
article,
aside,
audio,
b,
big,
blockquote,
body,
canvas,
caption,
center,
cite,
code,
dd,
del,
details,
dfn,
div,
dl,
dt,
em,
embed,
fieldset,
figcaption,
figure,
footer,
form,
h1,
h2,
h3,
h4,
h5,
h6,
header,
hgroup,
html,
i,
iframe,
img,
ins,
kbd,
label,
legend,
li,
mark,
menu,
nav,
object,
ol,
output,
p,
pre,
q,
ruby,
s,
samp,
section,
small,
span,
strike,
strong,
sub,
summary,
sup,
table,
tbody,
td,
tfoot,
th,
thead,
time,
tr,
tt,
u,
ul,
var,
video {
  border: 0;
  font: inherit;
  font-size: 100%;
  margin: 0;
  padding: 0;
  vertical-align: baseline;
}

a {
  text-decoration: none;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

body {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  line-height: 1;
}

ol,
ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote::before,
blockquote::after,
q::before,
q::after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

.svg-icons {
  display: none;
}

html,
body {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  background: var(--color-sand);
  color: var(--color-grey-900);
  font-family: var(--font-family-base);
  width: 100%;
}

*,
*::before,
*::after {
  -webkit-box-sizing: inherit;
          box-sizing: inherit;
}

body {
  font-size: 14px;
}
body.blocked {
  overflow: hidden;
}
@media only screen and (min-width: 1024px) {
  body {
    font-size: 16px;
  }
}
body::after {
  content: "small";
  display: none;
}
@media only screen and (min-width: 600px) {
  body::after {
    content: "medium";
  }
}
@media only screen and (min-width: 1024px) {
  body::after {
    content: "large";
  }
}
@media only screen and (min-width: large-desktop) {
  body::after {
    content: "extra-large";
  }
}
@media screen and (orientation: portrait) {
  body::before {
    content: "portrait";
    display: none;
  }
}

main {
  opacity: 1;
  -webkit-transform: none;
          transform: none;
  /* This pseudo element identifies when the styles have loaded are loaded in the
    app and is used in the component-initializer.js. Before, the styles could
    have a delay in the load and at the same time be running some scripts that
    can depend on these styles for proper visual behavior. Now, with this
    pseudo-element, when the styles have already been loaded, the component
    initializer evaluates if the pseudo-element is found, if so, executes the
    Javascript services. */
}
main::after {
  content: "loaded";
  display: none;
}

/*
 * Hide only visually, but have it available for screen readers:
 * https://snook.ca/archives/html_and_css/hiding-content-for-accessibility
 */
.visually-hidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: auto;
  margin: 0;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}

.disable-scroll {
  overflow: hidden;
}